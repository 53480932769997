import {
  Config,
  getConfigFromSnapshot,
  getCycle,
} from '@exivity/dashboard-common'
import { useSnapshot } from './useDoc'
import { useToday } from './useToday'

export function useConfig() {
  const snapshot = useSnapshot('config')

  if (!snapshot) {
    return undefined
  }

  return getConfigFromSnapshot(snapshot)
}

export function useCurrentCycle(config?: Config) {
  const today = useToday()

  if (!config) {
    return
  }

  return getCycle(today, config)
}
