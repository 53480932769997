import {
  DEFAULT_SHOW_PREFERENCE,
  getComponentsPreference,
  getShowPreference,
  Issue,
  IssueStatus,
  IssueType,
  Profile,
} from '@exivity/dashboard-common'

const DEFAULT_STATUS_ORDER: IssueStatus[] = [
  'started',
  'todo',
  'backlog',
  'done',
  'released',
  'cancelled',
]

const DEFAULT_TYPE_ORDER: IssueType[] = ['bug', 'feature', 'chore']

const COMPLETE_STATUSES: IssueStatus[] = ['done', 'released', 'cancelled']

export function globalIssueFilter({
  userProfile,
  activeProfile,
  userIsActive,
}: {
  userProfile: Profile
  activeProfile: Profile
  userIsActive: boolean
}) {
  let show = getShowPreference(userProfile)
  let components = getComponentsPreference(userProfile)

  if (!userIsActive) {
    show = DEFAULT_SHOW_PREFERENCE
    components = []
  }

  return function (issue: Issue) {
    if (!activeProfile) {
      return false
    }

    if (
      show !== 'include_complete' &&
      COMPLETE_STATUSES.includes(issue.status)
    ) {
      return false
    }

    if (show === 'only_assigned') {
      return issue.assignee === activeProfile.email
    }

    if (show === 'assigned_unassigned') {
      if (!components || components.length === 0) {
        return issue.assignee === activeProfile.email || issue.assignee === null
      } else {
        return (
          issue.assignee === activeProfile.email ||
          (issue.assignee === null &&
            components?.some((item) => issue.components.includes(item)))
        )
      }
    }

    if (show === 'only_unassigned') {
      if (!components || components.length === 0) {
        return issue.assignee === null
      } else {
        return (
          issue.assignee === null &&
          components?.some((item) => issue.components.includes(item))
        )
      }
    }

    // show === 'all' | 'include_complete'
    if (!components || components.length === 0) {
      return true
    } else {
      return components?.some((item) => issue.components.includes(item))
    }
  }
}

export function makeIssuesSorter({
  statusOrder = DEFAULT_STATUS_ORDER,
  typeOrder = DEFAULT_TYPE_ORDER,
} = {}) {
  return function (a: Issue, b: Issue) {
    if (a.type === b.type && a.status === b.status) {
      return a.priority === 'high' ? -1 : 1
    }

    if (a.status === b.status) {
      return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type)
    }

    return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
  }
}
