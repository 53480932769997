import styled from 'styled-components'
import { useDarkMode } from '../hooks'

type Props = {
  children: React.ReactNode
}

const StyledHeader = styled.header<{ darkMode: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  background-color: ${({ darkMode }) => (darkMode ? '#24282f' : '#f8f8f8')};
  height: 4rem;
  padding: 0 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export function Header({ children }: Props) {
  console.debug('rendering Header')

  const darkMode = useDarkMode(true)

  return <StyledHeader darkMode={darkMode.value}>{children}</StyledHeader>
}
