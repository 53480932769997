import styled from 'styled-components'

const Frame = styled.iframe`
  height: calc(100vh - 30px - 3.5rem);
  width: 100%;

  border: 1px solid #3b4049;
`

export function Release() {
  return <Frame src="https://dev.exivity.net:5002" />
}
