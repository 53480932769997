import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
} from '@blueprintjs/core'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { useAuth, useCollectionRef } from '../hooks'
import { toaster } from './Toaster'

type Props = {
  onClose: () => void
  isOpen: boolean
}

function sluggify(str: string) {
  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '-') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export function AddSong({ isOpen, onClose }: Props) {
  console.debug('rendering AddSong')

  const { user } = useAuth()
  const songsRef = useCollectionRef('songs')
  const [songUrl, setSongUrl] = useState('')
  const [songSaving, setSongSaving] = useState(false)

  function addSong() {
    if (songUrl) {
      setSongSaving(true)
      songsRef
        .doc(sluggify(songUrl))
        .set({
          added_by: user?.email,
          url: songUrl,
        })
        .then(() => {
          // See https://stackoverflow.com/a/48610973
          ReactDOM.unstable_batchedUpdates(() => {
            setSongSaving(false)
            setSongUrl('')
            onClose()
          })
          toaster.current?.show({
            intent: 'success',
            message: 'Song added. Thank you!',
          })
        })
        .catch((error) => {
          toaster.current?.show({
            intent: 'danger',
            message: error.message,
          })
          setSongSaving(false)
        })
    } else {
      toaster.current?.show({
        intent: 'danger',
        message: 'Please fill in a URL',
      })
    }
  }

  return (
    <Dialog icon="music" title="Add song" onClose={onClose} isOpen={isOpen}>
      <div className={Classes.DIALOG_BODY}>
        <p>
          Songs you add will be used in the daily Slack updates in the{' '}
          <a
            href="https://exivity.slack.com/archives/C01NGK9MAHE"
            target="_blank"
            rel="noreferrer"
          >
            #dev-today
          </a>{' '}
          channel.
        </p>
        <FormGroup
          helperText="For example https://www.youtube.com/watch?v=T4463eO8QEI"
          labelFor="url"
        >
          <InputGroup
            fill
            required
            id="url"
            type="url"
            placeholder="URL"
            value={songUrl}
            onChange={(event) => {
              setSongUrl(event.target.value.trim())
            }}
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent="primary" onClick={addSong} loading={songSaving}>
            Add
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
