import React from 'react'
import styled from 'styled-components'
import { useDarkMode } from '../hooks'
import { Toaster } from './Toaster'

const Container = styled.div<{ darkMode: boolean }>`
  display: flex;
  min-height: 100%;
  padding: 4rem 1rem 1rem;
  box-sizing: border-box;

  background-color: ${({ darkMode }) => (darkMode ? '#24282f' : '#f8f8f8')};
`

export function Layout({ children }: { children: React.ReactNode }) {
  console.debug('rendering Layout')

  const darkMode = useDarkMode(true)

  return (
    <Container darkMode={darkMode.value}>
      <Toaster />
      {children}
    </Container>
  )
}
