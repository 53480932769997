import { useCallback, useEffect, useRef, useState } from 'react'
import { callFunctionWithFirebase, Functions } from '../api'
import { useApp } from './useApp'

export function useFunction<T extends keyof Functions>(
  name: T,
  args?: Parameters<Functions[T]>
) {
  const app = useApp()

  return useCallback(
    function () {
      return callFunctionWithFirebase(app, name, args)
    },
    [app, args, name]
  )
}

export function useFunctionResult<T extends keyof Functions>(
  name: T,
  args?: Parameters<Functions[T]>
) {
  const mounted = useRef<boolean>()
  const [data, setData] = useState<ReturnType<Functions[T]>>()
  const [error, setError] = useState<Error>()
  const [fnPromise, setFnPromise] = useState<Promise<unknown>>()
  const app = useApp()

  const callFn = useCallback(() => {
    return callFunctionWithFirebase(app, name, args)
  }, [app, args, name])

  const trigger = useCallback(() => {
    const promise = callFn()
    setData(undefined)
    setFnPromise(promise)
    promise
      .then((data) => {
        if (!mounted.current) {
          return
        }

        if (data) {
          console.debug('received function results for ' + name)
          setData(data)
        }
      })
      .catch((error) => {
        if (!mounted.current) {
          return
        }

        console.debug('received function error for ' + name)
        setError(error)
      })
  }, [callFn, name])

  useEffect(() => {
    mounted.current = true

    trigger()

    return () => {
      mounted.current = false
    }
  }, [trigger])

  if (data) {
    const dataWithRefresh: ReturnType<Functions[T]> & {
      refresh: () => void
    } = data as any
    dataWithRefresh.refresh = trigger

    return dataWithRefresh
  }

  if (error) {
    throw error
  }

  if (fnPromise) {
    throw fnPromise
  }

  return undefined
}
