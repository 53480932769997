import { Button } from '@blueprintjs/core'
import React from 'react'
import styled from 'styled-components'
import { Callout } from './Callout'
import { Overlay } from './overlays'

type Props = {
  hide: (...args: any) => void
}

const gifs = [
  'lMameLIF8voLu8HxWV',
  '3ornkdtVzQfIRpwfug',
  'IwAZ6dvvvaTtdI8SD5',
  'doPrWYzSG1Vao',
  'l0MYt5jPR6QX5pnqM',
  'l3q2Z6S6n38zjPswo',
  'l2JdXy8xasypvE0Ni',
  'MS3XuWjQV1FiU',
  'hTh9bSbUPWMWk',
  'gqDIOYUKHrNBK',
  'WgAji3BDcE46s',
  '3oEjHZMFYibQnjvTq0',
  'mCJkHm7o6vSoAKcLY7',
  'l1J3udQhaqfinsHCg',
]

const Gif = styled.img`
  display: block;
  margin-bottom: 1rem;
  height: calc(100vh - 14rem);
  max-width: calc(100vw - 4rem);

  object-fit: contain;
`

export function Weekend({ hide }: Props) {
  console.log('rendering Weekend')

  const randomGif = gifs[Math.floor(Math.random() * gifs.length)]
  const url = `https://media.giphy.com/media/${randomGif}/giphy.gif`

  return (
    <Overlay>
      <Callout
        icon={null}
        intent="primary"
        header="🎉 Weekend"
        collapsible={false}
        style={{ textAlign: 'center' }}
      />
      <div>
        <Gif alt="Giphy" src={url} />
      </div>
      <div>
        <Button large icon="briefcase" onClick={hide}>
          I want to work!
        </Button>
      </div>
    </Overlay>
  )
}
