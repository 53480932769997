import { Builds, Config } from '@exivity/dashboard-common'
import { firebasePromise } from '../context/Firebase'
import { Firebase } from './firebase'

const REGION = 'europe-west1'

export type Functions = {
  helloWorld: () => string
  config: () => Config
  pull: () => string
  builds: () => Builds
}

export async function callFunctionWithFirebase<T extends keyof Functions>(
  firebase: Firebase,
  name: T,
  args?: Parameters<Functions[T]>
) {
  const functions = firebase.functions(REGION)

  if (process.env.NODE_ENV === 'development') {
    functions.useEmulator('localhost', 5001)
  }

  const fn = functions.httpsCallable(name)
  const result = await fn(args)

  return result.data as ReturnType<Functions[T]>
}

export async function callFunction<T extends keyof Functions>(
  name: T,
  args?: Parameters<Functions[T]>
) {
  const firebase = await firebasePromise

  return callFunctionWithFirebase(firebase, name, args)
}
