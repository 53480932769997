import { Button, Classes, InputGroup } from '@blueprintjs/core'
import React from 'react'
import { useCurrentRoute, useNavigation } from 'react-navi'
import styled from 'styled-components'
import { useSearch } from '../hooks'
import { Tooltip } from './Tooltip'

const StyledSearch = styled(InputGroup)`
  width: 20rem;
  display: none;

  @media (min-width: 768px) {
  }

  @media (min-width: 960px) {
  }

  @media (min-width: 1200px) {
    display: initial;
  }

  @media (min-width: 1440px) {
    width: 30rem;
  }
`

export function Search() {
  console.debug('rendering Search')

  const { term, setTerm } = useSearch()
  const navigation = useNavigation()
  const route = useCurrentRoute()

  return (
    <StyledSearch
      type="search"
      large
      value={term}
      onChange={(event) => setTerm(event.target.value)}
      onFocus={() =>
        route.url.href !== '/search' && navigation.navigate('/search')
      }
      onBlur={() => !term && navigation.goBack()}
      className={Classes.ROUND}
      leftIcon="search"
      rightElement={
        <Tooltip placement="bottom" content="Advanced search syntax">
          <Button
            minimal
            icon="help"
            className={Classes.ROUND}
            onClick={() =>
              window.open('https://fusejs.io/examples.html#extended-search')
            }
          />
        </Tooltip>
      }
      placeholder="Search..."
    />
  )
}
