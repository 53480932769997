import { Classes, Icon, IconName, Intent } from '@blueprintjs/core'
import { getComponentsPreference, Issue, PR } from '@exivity/dashboard-common'
import { isAfter, sub } from 'date-fns'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { makeIssuesSorter, makePRSorter } from '../api'
import { useRemoteData } from '../hooks'
import { Callout } from './Callout'
import { CardContainer } from './Card'
import { IssueCard } from './IssueCard'
import { PrCard } from './PrCard'
import { Table } from './Table'
import { Tooltip } from './Tooltip'

type MetricProps = {
  label: string
  icon: IconName
  intent: Intent
  weeks: number
  value: number
  onClick: () => void
}

const ranges: { label: string; weeks: number }[] = [
  {
    label: 'Last two weeks',
    weeks: 2,
  },
  {
    label: 'Last quarter',
    weeks: 13,
  },
  {
    label: 'Last year',
    weeks: 52,
  },
]

const Metric = styled.span.attrs<MetricProps>(
  ({ label, icon, intent, weeks, value, onClick }) => {
    return {
      children: (
        <Tooltip
          content={
            <>
              {label}
              <br />
              Per week: {Math.ceil(value / weeks).toString()}
              <br />
              Click to show {value > 100 ? 'first 100 items' : ''}
            </>
          }
          placement="top"
        >
          <div onClick={onClick}>
            <Icon iconSize={32} intent={intent} icon={icon} />
            <br />
            <span className={` ${Classes.MONOSPACE_TEXT}`}>
              {value.toString()}
            </span>
          </div>
        </Tooltip>
      ),
    }
  }
)<MetricProps>`
  display: inline-block;
  width: 50%;

  text-align: center;
  font-size: 2rem;
`

export function Done() {
  console.debug('rendering Done')

  let {
    userProfile,
    userIsActive,
    activeProfile,
    profiles,
    allIssues,
    allPrs,
  } = useRemoteData()

  const [renderIssues, setRenderIssues] = useState<Issue[] | null>(null)
  const [renderPrs, setRenderPrs] = useState<PR[] | null>(null)

  const components = getComponentsPreference(userProfile)

  allIssues = allIssues.sort(makeIssuesSorter())
  allPrs = allPrs.sort(makePRSorter())

  const matrix = useMemo(() => {
    const issues =
      components && components.length > 0
        ? allIssues.filter((issue) =>
            components?.some((item) => issue.components.includes(item))
          )
        : allIssues

    const prs =
      components && components.length > 0
        ? allPrs.filter((pr) => components.includes(pr.component || ''))
        : allPrs

    const activeProfileIssues = issues.filter(
      (issue) => issue.assignee === activeProfile.email
    )
    const activeProfilePrs = prs.filter(
      (pr) => pr.assignee === activeProfile.email
    )

    const filters = [
      {
        label: 'Team',
        issues,
        prs,
      },
      {
        label: userIsActive
          ? 'Me'
          : activeProfile.displayName || activeProfile.email,
        issues: activeProfileIssues,
        prs: activeProfilePrs,
      },
    ]

    return filters.map((filter) => {
      return {
        label: filter.label,
        ranges: ranges.map((range) => {
          const since = sub(new Date(), { weeks: range.weeks })

          return {
            ...range,
            issues: filter.issues.filter((issue) => {
              return issue.closed && isAfter(new Date(issue.closed), since)
            }),
            prs: filter.prs.filter((pr) => {
              return pr.closed && isAfter(new Date(pr.closed), since)
            }),
          }
        }),
      }
    })
  }, [activeProfile, allIssues, allPrs, components, userIsActive])

  return (
    <>
      <Callout icon="tick" intent="success" header="Done">
        <p className={Classes.RUNNING_TEXT}>
          Recently closed/merged pull requests and resolved issues. Also some
          stats.
        </p>
      </Callout>
      <Table>
        <colgroup>
          <col width={200} />
          {ranges.map((range) => (
            <col key={range.label} width={200} />
          ))}
        </colgroup>
        <thead>
          <tr>
            <th />
            {ranges.map((range) => (
              <th key={range.label}>{range.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {matrix.map((item) => (
            <tr key={item.label}>
              <th>{item.label}</th>
              {item.ranges.map((range) => (
                <td key={range.label}>
                  <Metric
                    label="Resolved issues"
                    icon="heart"
                    intent="success"
                    weeks={range.weeks}
                    value={range.issues.length}
                    onClick={() => {
                      setRenderIssues(range.issues.slice(0, 100))
                      setRenderPrs(null)
                    }}
                  />
                  <Metric
                    label="Merged or closed pull requests"
                    icon="git-merge"
                    intent="primary"
                    weeks={range.weeks}
                    value={range.prs.length}
                    onClick={() => {
                      setRenderIssues(null)
                      setRenderPrs(range.prs.slice(0, 100))
                    }}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {renderIssues && (
        <>
          <Callout
            icon="heart"
            intent="success"
            header="Issues"
            collapsible={false}
          />
          <CardContainer>
            {renderIssues.map((issue) => (
              <IssueCard key={issue.key} issue={issue} profiles={profiles} />
            ))}
          </CardContainer>
        </>
      )}
      {renderPrs && (
        <>
          <Callout
            icon="git-merge"
            intent="primary"
            header="Pull requests"
            collapsible={false}
          />
          <CardContainer>
            {renderPrs.map((pr) => (
              <PrCard key={pr.key} pr={pr} profiles={profiles} />
            ))}
          </CardContainer>
        </>
      )}
    </>
  )
}
