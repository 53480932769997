import { Button, H2 } from '@blueprintjs/core'
import React, { Component } from 'react'
import { Overlay } from './Overlay'

type Props = { children: React.ReactNode }

export class ErrorBoundary extends Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    // console.error({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <Overlay>
          <H2>Error</H2>
          <p>Something went horribly wrong</p>
          <div>
            <Button icon="refresh" onClick={() => document.location.reload()}>
              Reload
            </Button>
          </div>
        </Overlay>
      )
    }

    return this.props.children
  }
}
