import { Classes, H3, Intent, Tag } from '@blueprintjs/core'
import { format, formatDistance } from 'date-fns'
import React from 'react'
import styled, { css } from 'styled-components'
import { useDarkMode } from '../hooks'
import { Avatar } from './Avatar'
import { Tooltip } from './Tooltip'

type CardProps = {
  href?: string
  intent?: Intent
  type?: 'issue' | 'project' | 'pr'
}

export const CardContainer = styled.div`
  display: grid;
  padding: 0 1px;
  margin-bottom: 6rem;

  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }
`

export const Card = styled.a.attrs<CardProps>(({ href }) => ({
  className: [Classes.CARD, Classes.ELEVATION_0, Classes.INTERACTIVE].join(' '),
  tabIndex: 0,
  target: '_blank',
  rel: 'noreferrer',
}))<CardProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    text-decoration: none;
  }

  border: 5px solid
    ${({ intent }) =>
      intent === 'danger'
        ? 'rgba(219, 55, 55, 1)'
        : intent === 'warning'
        ? 'rgba(217, 130, 43, 1)'
        : intent === 'primary'
        ? 'rgba(19, 124, 189, 1)'
        : 'none'};

  ${({ type }) => {
    if (type) {
      return css`
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;

          height: 10px;
          width: 10px;
          border-radius: 50px;
          color: ${type === 'issue'
            ? 'rgb(200, 200, 200)'
            : type === 'project'
            ? 'rgb(50, 50, 50)'
            : type === 'pr'
            ? 'rgb(200, 200, 200)'
            : 'transparent'};
          border: 1px solid currentcolor;
          background-color: ${type === 'issue'
            ? 'currentcolor'
            : type === 'project'
            ? 'currentcolor'
            : type === 'pr'
            ? 'transparent'
            : 'transparent'};
        }
      `
    }
  }}
`

export const CardHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

const StyledCardOverflowBody = styled.div<{ darkMode: boolean }>`
  position: relative;
  flex-grow: 1;
  margin-bottom: 1rem;

  max-height: 200px;
  overflow: hidden;

  &::after {
    position: absolute;
    top: calc(200px - 4rem);
    left: 0;
    right: 0;
    height: 4rem;

    content: '';
    background: linear-gradient(
      to bottom,
      ${({ darkMode }) =>
        darkMode
          ? 'rgba(48, 64, 77, 0), rgba(48, 64, 77, 1)'
          : 'rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)'}
    );
  }
`

export function CardOverflowBody({
  children,
  ...rest
}: {
  children: React.ReactNode
}) {
  const darkMode = useDarkMode()

  return (
    <StyledCardOverflowBody darkMode={darkMode.value} {...rest}>
      {children}
    </StyledCardOverflowBody>
  )
}

export const CardTitle = styled(H3)`
  display: flex;
  justify-content: space-between;

  word-break: break-word;
  margin-bottom: 1rem;
  font-weight: 500;
`

export const CardDescription = styled.p.attrs(() => ({
  className: Classes.RUNNING_TEXT,
}))`
  word-break: break-word;
  font-weight: 300;

  &:last-child {
    margin-bottom: 0;
  }
`

export const CardFooter = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-top: 0.2rem;
  }
`

export const CardAvatars = styled.div.attrs(() => ({
  darkMode: useDarkMode().value,
}))<{ darkMode: boolean }>`
  display: flex;

  &:not(:empty) {
    margin-right: 0.5rem;
  }

  & > * {
    z-index: 1;

    &:not(:first-child) {
      margin-left: -0.5rem;
    }
  }

  & > *:hover {
    z-index: 2;
  }

  ${Avatar} {
    margin-right: 0;

    > div {
      box-shadow: 0 0 0 2px
        ${({ darkMode }) => (darkMode ? '#30404d' : '#ffffff')};
    }

    img {
      background-color: ${({ darkMode }) => (darkMode ? '#454e56' : '#c8c8c8')};
    }
  }
`

export const CardIdentifier = styled(Tag).attrs(() => ({}))`
  margin-right: 0.2rem;
`

export const CardUpdated = styled.span.attrs<{ updated: string }>(
  ({ updated }) => ({
    className: [Classes.TEXT_MUTED, Classes.TEXT_SMALL],
    children: updated ? (
      <Tooltip
        placement="top"
        content={`Last updated: ${format(new Date(updated), 'PPPp')}`}
      >
        {formatDistance(new Date(updated), new Date(), {
          addSuffix: true,
        })}
      </Tooltip>
    ) : null,
  })
)<{ updated: string }>`
  margin-top: calc(2px + 0.2rem);
  flex-grow: 1;
  text-align: right;
`
