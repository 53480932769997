import { H1 } from '@blueprintjs/core'
import { hasOwnProperty, Issue, PR, Project } from '@exivity/dashboard-common'
import React from 'react'
import { useRemoteData, useSearch } from '../hooks'
import { CardContainer } from './Card'
import { IssueCard } from './IssueCard'
import { PrCard } from './PrCard'
import { ProjectCard } from './ProjectCard'

type Result = Issue | Project | PR

function isIssue(item: Result): item is Issue {
  return hasOwnProperty(item, 'project')
}

function isProject(item: Result): item is Project {
  return hasOwnProperty(item, 'specURL')
}

function isPR(item: Result): item is PR {
  return hasOwnProperty(item, 'draft')
}

export function SearchResults() {
  console.debug('rendering SearchResults')

  const { profiles } = useRemoteData()
  const { term, searching, results } = useSearch()

  if (term.length < 2) {
    return <H1>👇 Enter two or more characters</H1>
  }

  if (searching) {
    return <H1>🔍 Searching...</H1>
  }

  if (results.length === 0) {
    return <H1>🤷‍♀️ No results</H1>
  }

  return (
    <>
      <H1>
        🔍{' '}
        {results.length > 20
          ? 'Showing first 20 results'
          : `${results.length} result${results.length !== 1 ? 's' : ''}`}
      </H1>
      <CardContainer>
        {results
          .slice(0, 20)
          .map(({ item }) =>
            isIssue(item) ? (
              <IssueCard key={item.key} dot issue={item} profiles={profiles} />
            ) : isProject(item) ? (
              <ProjectCard key={item.key} dot project={item} />
            ) : isPR(item) ? (
              <PrCard key={item.key} dot pr={item} profiles={profiles} />
            ) : (
              'Unknown item'
            )
          )}
      </CardContainer>
    </>
  )
}
