import { Profile } from '@exivity/dashboard-common'
import firebase from 'firebase/app'
import React, { createContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Loading, Unauthorized } from '../components'
import { useApp, useCollectionRef } from '../hooks'

type Auth = {
  signIn: () => void
  signOut: () => void
  user: firebase.User | null
}

type Props = {
  children: React.ReactNode
}

const initialState: Auth = {
  signIn: () => {},
  signOut: () => {},
  user: null,
}

export const AuthContext = createContext(initialState)

export function AuthProvider({ children }: Props) {
  const app = useApp()
  const [user, setUser] = useState<firebase.User | null>(null)
  const [loading, setLoading] = useState(true)
  const profilesRef = useCollectionRef('profiles')
  const provider = new firebase.auth.GoogleAuthProvider()

  useEffect(() => {
    return app.auth().onAuthStateChanged(async (user) => {
      console.debug('received new auth state')

      // See https://stackoverflow.com/a/48610973
      ReactDOM.unstable_batchedUpdates(() => {
        setLoading(false)
        setUser(user)
      })

      if (user && user.email && profilesRef) {
        const docRef = await profilesRef.doc(user.email).get()

        if (docRef.exists) {
          profilesRef.doc(user.email).update({
            displayName: user.displayName,
            photoURL: user.photoURL,
          } as Profile)
        } else {
          profilesRef.doc(user.email).set({
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
          } as Profile)
        }
      }
    })
  }, [app, profilesRef])

  function signIn() {
    app.auth().signInWithPopup(provider)
  }

  function signOut() {
    app.auth().signOut()
  }

  if (!loading && !user) {
    return <Unauthorized signIn={signIn} />
  }

  if (loading) {
    return <Loading />
  }

  const data = {
    user,
    signIn,
    signOut,
  }

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>
}
