import { getDay } from 'date-fns'
import React, { useState } from 'react'
import { makePRSorter } from '../api'
import { makeIssuesSorter } from '../api/issues'
import { useRemoteData } from '../hooks'
import { BugfixMonday } from './BugfixMonday'
import { CooldownPeriod } from './CooldownPeriod'
import { HighPriority } from './HighPriority'
import { ProjectCycle } from './ProjectCycle'
import { PRs } from './PRs'
import { Weekend } from './Weekend'

function isWeekend() {
  return [0, 6].includes(getDay(new Date()))
}

export function Today() {
  console.debug('rendering Today')

  const [hideWeekend, setHideWeekend] = useState(false)

  let { currentCycle, filteredIssues, filteredPrs, allProjects } =
    useRemoteData()

  filteredIssues = filteredIssues.sort(makeIssuesSorter())
  filteredPrs = filteredPrs.sort(makePRSorter())

  if (isWeekend() && hideWeekend === false) {
    return <Weekend hide={setHideWeekend} />
  }

  return (
    <>
      <PRs prs={filteredPrs} />
      <HighPriority issues={filteredIssues} />
      {currentCycle.name === 'project' ? (
        <ProjectCycle
          filteredIssues={filteredIssues}
          projects={allProjects}
          week={currentCycle.week}
        />
      ) : currentCycle.name === 'bugfix_monday' ? (
        <BugfixMonday issues={filteredIssues} />
      ) : (
        <CooldownPeriod issues={filteredIssues} week={currentCycle.week} />
      )}
    </>
  )
}
