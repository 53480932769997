import { Button, H2 } from '@blueprintjs/core'
import React from 'react'
import { Overlay } from './Overlay'

type Props = {
  signIn: () => void
}

export function Unauthorized({ signIn }: Props) {
  console.debug('rendering Unauthorized')

  return (
    <Overlay>
      <H2>Please log in first</H2>
      <Button large icon="unlock" onClick={signIn}>
        Continue with Google
      </Button>
    </Overlay>
  )
}
