import { createContext, useEffect, useState } from 'react'
import { Firebase, loadFirebase } from '../api'
import { Loading } from '../components'

type Props = {
  children: React.ReactNode
}

export const firebasePromise = loadFirebase()

export const FirebaseContext = createContext<Firebase>(null as any)

export function FirebaseProvider({ children }: Props) {
  const [value, setValue] = useState<Firebase>()

  useEffect(() => {
    firebasePromise.then(setValue)
  }, [])

  if (!value) {
    return <Loading />
  }

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  )
}
