import { Classes, MenuItem } from '@blueprintjs/core'
import { ItemPredicate, ItemRenderer, MultiSelect } from '@blueprintjs/select'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useRemoteData } from '../hooks'

type Props = {
  selected: string[]
  onChange: (components: string[]) => void
}

export const ComponentSelector = styled(MultiSelect.ofType<string>())`
  margin-right: 1rem;

  .${Classes.INPUT} {
    min-width: 10rem;
  }
`

const filterComponent: ItemPredicate<string> = (query, component) => {
  return component.toLowerCase().indexOf(query.toLowerCase()) >= 0
}

const renderComponent = (selected: string[]): ItemRenderer<string> => (
  component,
  { handleClick, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <MenuItem
      active={modifiers.active}
      icon={selected.includes(component) ? 'tick' : 'blank'}
      key={component}
      onClick={handleClick}
      text={component}
    />
  )
}

export function ComponentFilter({ selected, onChange }: Props) {
  const availableComponents = useRemoteData().config.components

  const handleSelect = useCallback(
    (component: string) => {
      if (selected.includes(component)) {
        onChange(selected.filter((item) => item !== component))
      } else {
        onChange([...selected, component])
      }
    },
    [onChange, selected]
  )

  return (
    <ComponentSelector
      placeholder="Filter components"
      items={availableComponents.sort()}
      selectedItems={selected}
      onItemSelect={handleSelect}
      onRemove={(component) =>
        onChange(selected.filter((item) => item !== component))
      }
      itemPredicate={filterComponent}
      itemRenderer={renderComponent(selected)}
      tagRenderer={(component) => component.substr(0, 3)}
      tagInputProps={{ addOnPaste: true, tagProps: { onRemove: undefined } }}
      popoverProps={{ placement: 'bottom-end' }}
      resetOnSelect
    />
  )
}
