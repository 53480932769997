import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

export type Firebase = firebase.app.App

const INIT_URL = '/__/firebase/init.js?useEmulator=true'

export function loadFirebase() {
  return new Promise<Firebase>((resolve, reject) => {
    if (typeof window.firebase !== 'undefined') {
      // Already initialized, skip
      return
    }

    // @ts-ignore
    window.firebase = firebase

    const script = document.createElement('script')
    script.setAttribute('src', INIT_URL)
    script.onload = function () {
      try {
        const app = firebase.app()
        const features = ([
          'auth',
          'database',
          'firestore',
          'functions',
          'messaging',
          'storage',
          'analytics',
          'remoteConfig',
          'performance',
        ] as const).filter((feature) => typeof app[feature] === 'function')
        console.debug(`Firebase SDK loaded with ${features.join(', ')}`)
        resolve(app)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    }
    document.body.appendChild(script)
  })
}
