import { Toaster as BaseToaster } from '@blueprintjs/core'
import React, { createRef } from 'react'

export const toaster = createRef<BaseToaster>()

export function Toaster() {
  console.debug('rendering Toaster')

  return <BaseToaster position="bottom" ref={toaster} />
}
