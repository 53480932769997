import {
  Config,
  Cycle,
  Issue,
  PR,
  Profile,
  Project,
} from '@exivity/dashboard-common'
import React, { createContext, useState } from 'react'
import { globalIssueFilter, globalPRFilter } from '../api'
import { Loading } from '../components'
import {
  useAuth,
  useCollectionMap,
  useCollectionRef,
  useCollectionSet,
  useConfig,
  useCurrentCycle,
} from '../hooks'

type RemoteData = {
  // useConfig
  config: Config

  // useCurrentCycle
  currentCycle: Cycle

  // useCollectionMap
  profiles: Record<string, Profile>
  userProfile: Profile
  userIsActive: boolean

  // useState
  activeProfile: Profile
  setActiveProfileEmail: (email: string) => void

  // inline function
  setPreference: <K extends keyof Required<Profile>['preferences']>(
    key: K,
    value: Required<Profile>['preferences'][K]
  ) => Promise<void>

  // useCollectionSet
  allIssues: Issue[]
  filteredIssues: Issue[]
  allPrs: PR[]
  filteredPrs: PR[]
  allProjects: Project[]
}

type Props = {
  children: React.ReactNode
}

export const RemoteDataContext = createContext<RemoteData>({} as any)

export function RemoteDataProvider({ children }: Props) {
  const [activeProfileEmail, setActiveProfileEmail] = useState<string>()
  const { user } = useAuth()
  const config = useConfig()
  const currentCycle = useCurrentCycle(config) as Cycle
  const profiles = useCollectionMap<Profile>('profiles')
  const profilesRef = useCollectionRef('profiles')
  const allIssues = useCollectionSet<Issue>('issues')
  const allPrs = useCollectionSet<PR>('prs')
  const allProjects = useCollectionSet<Project>('projects')

  if (user && !activeProfileEmail) {
    setActiveProfileEmail(user.email!)
  }

  if (
    !user ||
    !activeProfileEmail ||
    !config ||
    !currentCycle ||
    !allIssues ||
    !allPrs ||
    !allProjects ||
    !profiles ||
    !profiles[activeProfileEmail] ||
    !profiles[user.email!]
  ) {
    return <Loading />
  }

  async function setPreference<
    K extends keyof Required<Profile>['preferences']
  >(key: K, value: Required<Profile>['preferences'][K]) {
    if (user && user.email) {
      profilesRef.doc(user.email).update({
        [`preferences.${key}`]: value,
      })
    }
  }

  const activeProfile = profiles[activeProfileEmail]
  const userProfile = profiles[user.email!]
  const userIsActive = activeProfile.email === userProfile.email

  const filterArgs = { userProfile, activeProfile, userIsActive }
  const filteredIssues = allIssues.filter(globalIssueFilter(filterArgs))
  const filteredPrs = allPrs.filter(globalPRFilter(filterArgs))

  const data = {
    config,
    currentCycle,
    profiles,
    userProfile,
    userIsActive,
    activeProfile,
    setActiveProfileEmail,
    setPreference,
    allIssues,
    filteredIssues,
    allPrs,
    filteredPrs,
    allProjects,
  }

  return (
    <RemoteDataContext.Provider value={data}>
      {children}
    </RemoteDataContext.Provider>
  )
}
