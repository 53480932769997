import {
  getComponentsPreference,
  getOnlyRecentPreference,
  getShowPreference,
  ProfileShowPreference,
} from '@exivity/dashboard-common'
import React from 'react'
import { useCurrentRoute } from 'react-navi'
import styled from 'styled-components'
import { ONLY_RECENT_RANGE } from '../context'
import { useAuth, useRemoteData } from '../hooks'
import { Avatar } from './Avatar'
import { ComponentFilter, ComponentSelector } from './ComponentFilter'
import { ProfileSwitcher } from './ProfileSwitcher'
import { Switch } from './Switch'
import { Tooltip } from './Tooltip'
import { UserMenu } from './UserMenu'

const Name = styled.div`
  display: none;
  white-space: nowrap;
  margin-right: 1rem;
`

const Container = styled.div`
  flex-grow: 1;
  flex-basis: 50%;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${ComponentSelector}, ${Switch}, ${Avatar}, .bp3-button-text {
    display: none;
  }

  .bp3-button > * {
    margin: 0 7px;
  }

  @media (min-width: 960px) {
    ${ComponentSelector}, ${Switch}, ${Avatar}, .bp3-button-text {
      display: block;
    }

    .bp3-button > * {
      margin-left: 0;
    }
  }
`

export function Toolbar() {
  console.debug('rendering Toolbar')

  const { user } = useAuth()
  const route = useCurrentRoute()
  const { userProfile, userIsActive, setPreference } = useRemoteData()

  const show = getShowPreference(userProfile)
  const components = getComponentsPreference(userProfile)
  const onlyRecent = getOnlyRecentPreference(userProfile)

  const showComponentFilter =
    (userIsActive &&
      ['/today', '/planning'].includes(route.url.href) &&
      show !== 'only_assigned') ||
    ['/builds', '/search', '/done'].includes(route.url.href)
  const showUnassignedSwitch =
    userIsActive && ['/today', '/planning'].includes(route.url.href)
  const showRecentSwitch = ['/search'].includes(route.url.href)

  const unassignedSwitchEnabled = ([
    'only_assigned',
    'assigned_unassigned',
  ] as ProfileShowPreference[]).includes(show)

  if (!user) {
    return null
  }

  return (
    <Container>
      {showComponentFilter && (
        <ComponentFilter
          selected={components}
          onChange={(newComponents) => {
            setPreference('components', newComponents)
          }}
        />
      )}
      {showUnassignedSwitch && (
        <div style={{ marginRight: '1rem' }}>
          <Tooltip
            content={
              unassignedSwitchEnabled
                ? 'Enable to also show unassigned issues and pull requests. When enabled, you can optionally filter to show items related to certain components only.'
                : "Only active when 'Only assigned' or 'Assigned & unassigned' is selected in the Hamburger menu > Show."
            }
            placement="bottom"
          >
            <Switch
              label="Unassigned"
              disabled={!unassignedSwitchEnabled}
              checked={show === 'assigned_unassigned'}
              onChange={(event: any) => {
                setPreference(
                  'show',
                  event.target.checked ? 'assigned_unassigned' : 'only_assigned'
                )
              }}
            />
          </Tooltip>
        </div>
      )}
      {showRecentSwitch && (
        <div style={{ marginRight: '1rem' }}>
          <Tooltip
            content={`Only show cards updated in the last ${ONLY_RECENT_RANGE.months} months`}
            placement="bottom"
          >
            <Switch
              label="Recent"
              checked={onlyRecent}
              onChange={(event: any) => {
                setPreference('onlyRecent', !!event.target.checked)
              }}
            />
          </Tooltip>
        </div>
      )}
      <ProfileSwitcher />
      <Name>{user.displayName || user.email}</Name>
      <UserMenu />
    </Container>
  )
}
