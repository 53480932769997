import {
  Button as BaseButton,
  Callout as BaseCallout,
  Collapse,
  H4,
  ICalloutProps,
} from '@blueprintjs/core'
import React, { useState } from 'react'
import styled from 'styled-components'

type Props = ICalloutProps & {
  header: React.ReactNode
  collapsible?: boolean
  collapseButtonText?: string
}

const Button = styled(BaseButton).attrs(() => ({
  minimal: true,
}))`
  float: right;
  position: relative;
  top: -4px;
`

const StyledCallout = styled(BaseCallout)`
  padding: 1rem;
  margin-bottom: 1rem;
  max-width: 700px;

  .bp3-icon {
    top: 1rem !important;
  }

  p:last-child {
    margin-bottom: 0;
  }
`

const Header = styled(H4)`
  &:last-child {
    margin-bottom: 0 !important;
  }
`

export function Callout({
  collapsible = true,
  collapseButtonText,
  children,
  header,
  ...rest
}: Props) {
  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen((previous) => !previous)
  }

  return (
    <StyledCallout {...rest}>
      {collapsible ? (
        <Button icon={isOpen ? 'chevron-up' : 'chevron-down'} onClick={toggle}>
          {collapseButtonText}
        </Button>
      ) : null}
      <Header style={{ marginBottom: !collapsible || isOpen ? '1rem' : 0 }}>
        {header}
      </Header>
      {collapsible ? <Collapse isOpen={isOpen}>{children}</Collapse> : children}
    </StyledCallout>
  )
}
