import { H2 } from '@blueprintjs/core'
import React from 'react'
import { Overlay } from './Overlay'

export function NotFound() {
  return (
    <Overlay>
      <H2>Not Found</H2>
      <p>Perhaps the page has gone for a long walk?</p>
    </Overlay>
  )
}
