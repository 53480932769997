import React from 'react'
import { NotFoundBoundary } from 'react-navi'
import styled from 'styled-components'
import { NotFound } from './overlays'

const StyledMain = styled.main`
  flex-grow: 1;
`

export function Main({ children }: { children: React.ReactNode }) {
  console.debug('rendering Main')

  return (
    <StyledMain>
      <NotFoundBoundary render={() => <NotFound />}>
        {children}
      </NotFoundBoundary>
    </StyledMain>
  )
}
