import { Profile } from '@exivity/dashboard-common'

export function getProfile(
  profiles: Record<string, Profile>,
  email: string | null
) {
  return email
    ? profiles[email] ?? {
        email,
        displayName: null,
        photoURL: null,
      }
    : null
}
