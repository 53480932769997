import { Tab, Tabs } from '@blueprintjs/core'
import React from 'react'
import { useCurrentRoute, useNavigation } from 'react-navi'
import styled from 'styled-components'

const Container = styled.div`
  flex-grow: 1;
  flex-basis: 50%;

  .bp3-tab:not(:nth-child(2)):not(:nth-child(3)) {
    display: none;
  }

  @media (min-width: 960px) {
    .bp3-tab:not(:nth-child(2)):not(:nth-child(3)) {
      display: initial;
    }
  }
`

export function Navigation() {
  console.debug('rendering Navigation')

  const route = useCurrentRoute()
  const navigation = useNavigation()
  const selectedTabId = route.url.href.substr(1)

  function handleTabChange(tab: string) {
    navigation.navigate(`/${tab}`)
  }

  return (
    <Container>
      <Tabs onChange={handleTabChange} selectedTabId={selectedTabId || 'today'}>
        <Tab id="today" title="⚡ Today" />
        <Tab id="planning" title="📅 Planning" />
        <Tab id="done" title="✅ Done" />
        <Tab id="roadmap" title="Roadmap" />
        <Tab id="builds" title="Builds" />
        <Tab id="release" title="Release" />
      </Tabs>
    </Container>
  )
}
