import { Button } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import { Project } from '@exivity/dashboard-common'
import React from 'react'

export function ProjectSpecButton({ project }: { project: Project }) {
  return (
    <Tooltip2 content="Specs" placement="top">
      <Button
        icon="data-lineage"
        minimal
        onClick={() => window.open(project.specURL as string)}
      />
    </Tooltip2>
  )
}
