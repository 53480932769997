import { Classes } from '@blueprintjs/core'
import { Issue, IssueStatus } from '@exivity/dashboard-common'
import React from 'react'
import { useRemoteData } from '../hooks'
import { Callout } from './Callout'
import { CardContainer } from './Card'
import { IssueCard } from './IssueCard'

type Props = {
  issues: Issue[]
}

function bugfixMondayFilter(issue: Issue) {
  return (
    (['todo', 'started'] as IssueStatus[]).includes(issue.status) &&
    issue.type === 'bug'
  )
}

export function BugfixMonday({ issues }: Props) {
  console.debug('rendering BugfixMonday')

  const { profiles } = useRemoteData()

  const filteredIssues = issues.filter(bugfixMondayFilter)

  return (
    <>
      <Callout intent="danger" icon="virus" header="Bugfix Monday">
        <p className={Classes.RUNNING_TEXT}>
          Every two weeks there's an entire day reserved for fixing bugs. Any
          accepted bug in the backlog can be worked on.
        </p>
        <a
          href="https://app.gitbook.com/@exivity/s/docs/product/collaboration-guide/issues/bugs#bugfix-monday"
          target="_blank"
          rel="noreferrer"
        >
          Read more about bugfix monday on the internal docs
        </a>
      </Callout>
      <CardContainer>
        {filteredIssues.length === 0 ? (
          <p>No issues to work on.</p>
        ) : (
          filteredIssues.map((issue) => (
            <IssueCard key={issue.key} issue={issue} profiles={profiles} />
          ))
        )}
      </CardContainer>
    </>
  )
}
