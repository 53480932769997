import {
  DEFAULT_SHOW_PREFERENCE,
  getComponentsPreference,
  getShowPreference,
  PR,
  Profile,
  PrStatus,
} from '@exivity/dashboard-common'

const DEFAULT_STATUS_ORDER: PrStatus[] = [
  'review_required',
  'changes_requested',
  'approved',
  'merged',
  'closed',
]

const DEFAULT_DRAFT_ORDER: boolean[] = [false, true]

const COMPLETE_STATUSES: PrStatus[] = ['merged', 'closed']

const needsReview = (pr: PR) => pr.status === 'review_required'

const noAssignee = (pr: PR) => pr.assignee === null

const noReviewers = (pr: PR) => pr.reviewers.length === 0

const hasComponent = (pr: PR) => pr.component !== null

const isDraft = (pr: PR) => pr.draft

export function globalPRFilter({
  userProfile,
  activeProfile,
  userIsActive,
}: {
  userProfile: Profile
  activeProfile: Profile
  userIsActive: boolean
}) {
  let show = getShowPreference(userProfile)
  let componentsFilter = getComponentsPreference(userProfile)

  if (!userIsActive) {
    show = DEFAULT_SHOW_PREFERENCE
    componentsFilter = []
  }

  const isAssignedToMe = (pr: PR) => pr.assignee === activeProfile.email

  const needsReviewFromMe = (pr: PR) =>
    pr.reviewers.some((reviewer) => reviewer === activeProfile.email)

  const matchesComponentsFilter = (pr: PR) =>
    componentsFilter?.includes(pr.component || '')

  const assigned = (pr: PR) =>
    needsReviewFromMe(pr) ||
    ((!needsReview(pr) || isDraft(pr) || noReviewers(pr)) && isAssignedToMe(pr))

  const unassigned = (pr: PR) =>
    noAssignee(pr) &&
    ((needsReview(pr) && !isDraft(pr) && noReviewers(pr)) || !needsReview(pr))

  const shouldMatchComponentsFilter =
    componentsFilter && componentsFilter.length > 0

  const matchesComponents = (pr: PR) =>
    shouldMatchComponentsFilter ? matchesComponentsFilter(pr) : hasComponent(pr)

  return function (pr: PR) {
    if (!activeProfile) {
      return false
    }

    // Don't support the 'include_complete' filter (returns too many items)
    if (show === 'include_complete' || COMPLETE_STATUSES.includes(pr.status)) {
      return false
    }

    if (show === 'only_assigned') {
      return assigned(pr)
    }

    if (show === 'assigned_unassigned') {
      return assigned(pr) || (matchesComponents(pr) && unassigned(pr))
    }

    if (show === 'only_unassigned') {
      return matchesComponents(pr) && unassigned(pr)
    }

    // show === 'all'
    if (!componentsFilter || componentsFilter.length === 0) {
      return true
    } else {
      return matchesComponentsFilter(pr)
    }
  }
}

export function makePRSorter({
  statusOrder = DEFAULT_STATUS_ORDER,
  draftOrder = DEFAULT_DRAFT_ORDER,
} = {}) {
  return function (a: PR, b: PR) {
    if (a.draft === b.draft) {
      return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
    }

    return draftOrder.indexOf(a.draft) - draftOrder.indexOf(b.draft)
  }
}
