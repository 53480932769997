import {
  Button,
  Menu,
  MenuDivider,
  MenuItem as BaseMenuItem,
} from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import {
  getShowPreference,
  ProfileShowPreference,
} from '@exivity/dashboard-common'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAuth, useFunction, useRemoteData } from '../hooks'
import { AddSong } from './AddSong'
import { toaster } from './Toaster'

const TOAST_LOTTERY_CHANCE = 1 / 10

const MenuItem = styled(BaseMenuItem)`
  .bp3-icon-minus {
    visibility: hidden;
  }
`

export function UserMenu() {
  console.debug('rendering UserMenu')

  const { signOut } = useAuth()
  const [pulling, setPulling] = useState(false)
  const [addSongIsOpen, setAddSongIsOpen] = useState(false)
  const pull = useFunction('pull')
  const { userProfile, userIsActive, setPreference } = useRemoteData()

  useEffect(() => {
    const max = 1 / TOAST_LOTTERY_CHANCE
    const randomNumber = Math.floor(Math.random() * Math.floor(max))

    if (randomNumber !== 0) {
      return
    }

    toaster.current?.show({
      action: {
        icon: 'thumbs-up',
        text: 'Sure!',
        onClick: () => setAddSongIsOpen(true),
      },
      icon: 'music',
      intent: 'primary',
      message: 'Feel like adding a song to the database today?',
      timeout: 60 * 1000,
    })
  }, [])

  function triggerPull() {
    setPulling(true)
    pull().finally(() => {
      setPulling(false)
    })
  }

  const show = getShowPreference(userProfile)

  const showPreferences: Record<ProfileShowPreference, string> = {
    only_assigned: 'Only assigned',
    assigned_unassigned: 'Assigned & unassigned',
    only_unassigned: 'Only unassigned',
    all: 'All incomplete items',
    include_complete: 'Everything',
  }

  return (
    <>
      <Popover2
        content={
          <Menu>
            <MenuItem icon="filter" text="Filter" disabled={!userIsActive}>
              {Object.entries(showPreferences).map(
                ([showPreference, label]) => (
                  <MenuItem
                    key={showPreference}
                    active={show === showPreference}
                    icon={show === showPreference ? 'tick' : 'minus'}
                    text={label}
                    onClick={() => {
                      setPreference(
                        'show',
                        showPreference as ProfileShowPreference
                      )
                    }}
                  />
                )
              )}
            </MenuItem>
            <MenuItem
              icon="refresh"
              text="Refresh data"
              disabled={pulling}
              onClick={triggerPull}
            />
            <MenuDivider />
            <MenuItem
              icon="music"
              text="Add song"
              onClick={() => setAddSongIsOpen(true)}
            />
            <MenuDivider />
            <MenuItem icon="lock" text="Logout" onClick={signOut} />
          </Menu>
        }
        placement="bottom-end"
      >
        <Button icon="menu" minimal large loading={pulling} />
      </Popover2>
      <AddSong onClose={() => setAddSongIsOpen(false)} isOpen={addSongIsOpen} />
    </>
  )
}
