import { HTMLTable } from '@blueprintjs/core'
import styled from 'styled-components'

export const Table = styled(HTMLTable).attrs(() => ({
  interactive: true,
  bordered: true,
}))`
  margin-bottom: 2rem;

  td,
  th {
    vertical-align: middle !important;
    height: 52px;
  }
`
