import { Classes } from '@blueprintjs/core'
import { Project, ProjectStatus } from '@exivity/dashboard-common'
import React from 'react'
import { useRemoteData } from '../hooks'
import { Callout } from './Callout'
import { RoadmapContainer } from './RoadmapContainer'

function projectSorter(a: Project, b: Project) {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}

function makeStatusFilter(status: ProjectStatus) {
  return function (project: Project) {
    return project.status === status
  }
}

export function Roadmap() {
  console.debug('rendering Today')

  let { allProjects } = useRemoteData()

  allProjects = allProjects.sort(projectSorter)

  return (
    <>
      <Callout intent="success" icon="gantt-chart" header="Roadmap">
        <p className={Classes.RUNNING_TEXT}>
          The roadmap contains projects which are used to group work related to
          a larger cross-component feature.
        </p>
        <a
          href="https://app.gitbook.com/@exivity/s/docs/product/collaboration-guide/projects"
          target="_blank"
          rel="noreferrer"
        >
          Read more about project statuses on the internal docs
        </a>
        <br />
        <a href="https://exivity.com/roadmap/" target="_blank" rel="noreferrer">
          Public roadmap
        </a>
      </Callout>
      <RoadmapContainer
        title="🐱‍💻 In development"
        projects={allProjects.filter(makeStatusFilter('started'))}
      />
      <RoadmapContainer
        title="🚢 To be released"
        projects={allProjects.filter(makeStatusFilter('ready'))}
      />
      <RoadmapContainer
        title="🔮 Planning"
        projects={allProjects.filter(makeStatusFilter('planning'))}
      />
    </>
  )
}
