import { Button, Classes, Tag } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import { Project } from '@exivity/dashboard-common'
import React from 'react'
import styled from 'styled-components'
import { useDarkMode } from '../hooks'
import { CardContainer } from './Card'
import { ProjectSpecButton } from './ProjectSpecButton'

type Props = {
  project: Project
  tag?: string
  children: React.ReactNode
}

const StyledProjectContainer = styled.div`
  margin-bottom: 2rem;
`

const StyledTitle = styled.h2.attrs(() => ({
  className: Classes.HEADING,
}))<{ darkMode: boolean }>`
  position: sticky;
  top: calc(30px + 2rem);
  z-index: 1;

  padding-bottom: 0.5rem;
  background-color: ${({ darkMode }) => (darkMode ? '#24282f' : '#f8f8f8')};
`

export function ProjectContainer({ project, tag, children }: Props) {
  const darkMode = useDarkMode()

  return (
    <StyledProjectContainer>
      <StyledTitle darkMode={darkMode.value}>
        {project.name}{' '}
        {tag ? (
          <>
            <Tag
              minimal
              style={{ verticalAlign: 'middle', fontWeight: 'normal' }}
            >
              {tag}
            </Tag>{' '}
          </>
        ) : null}
        <Tooltip2 content="Jira epic" placement="top">
          <Button
            icon="link"
            minimal
            onClick={() =>
              window.open(`https://exivity.atlassian.net/browse/${project.key}`)
            }
          />
        </Tooltip2>
        {project.specURL && <ProjectSpecButton project={project} />}
        <Tooltip2 content="Create issue" placement="top">
          <Button
            icon="plus"
            minimal
            onClick={() =>
              window.open(
                `https://exivity.atlassian.net/secure/CreateIssue.jspa?issuetype=10105&pid=10002`
              )
            }
          />
        </Tooltip2>
      </StyledTitle>
      <CardContainer>{children}</CardContainer>
    </StyledProjectContainer>
  )
}
