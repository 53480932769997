import React from 'react'
import { makeIssuesSorter } from '../api'
import { useRemoteData } from '../hooks'
import { BugfixMonday } from './BugfixMonday'
import { CooldownPeriod } from './CooldownPeriod'
import { ProjectCycle } from './ProjectCycle'

export function Planning() {
  console.debug('rendering Planning')

  let { allIssues, filteredIssues, allProjects } = useRemoteData()

  filteredIssues = filteredIssues.sort(makeIssuesSorter())

  return (
    <>
      <ProjectCycle
        allIssues={allIssues}
        filteredIssues={filteredIssues}
        projects={allProjects}
        showEmptyProjects
      />
      <BugfixMonday issues={filteredIssues} />
      <CooldownPeriod issues={filteredIssues} />
    </>
  )
}
