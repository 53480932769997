import { Menu, MenuItem as BaseMenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import React from 'react'
import styled from 'styled-components'
import { useRemoteData } from '../hooks'
import { Avatar } from './Avatar'

const Container = styled.div`
  display: grid;
  margin-right: 1rem;
`

const MenuItem = styled(BaseMenuItem)`
  .bp3-icon-minus {
    visibility: hidden;
  }
`

export function ProfileSwitcher() {
  const { profiles, activeProfile, setActiveProfileEmail } = useRemoteData()

  return (
    <Container>
      <Popover2
        content={
          <Menu>
            {Object.entries(profiles || {}).map(([email, profile]) => (
              <MenuItem
                key={email}
                icon={activeProfile.email === email ? 'tick' : 'minus'}
                onClick={() => setActiveProfileEmail(email)}
                text={profile.displayName || email || 'Unknown'}
              />
            ))}
          </Menu>
        }
        placement="bottom"
      >
        <Avatar profile={activeProfile} tooltip="bottom" noMargin />
      </Popover2>
    </Container>
  )
}
