import { Classes } from '@blueprintjs/core'
import { Issue, IssueStatus } from '@exivity/dashboard-common'
import React from 'react'
import { useRemoteData } from '../hooks'
import { Callout } from './Callout'
import { CardContainer } from './Card'
import { IssueCard } from './IssueCard'

type Props = {
  issues: Issue[]
  week?: number
}

function cooldownPeriodFilter(issue: Issue) {
  return (
    (['todo', 'started'] as IssueStatus[]).includes(issue.status) &&
    !issue.project
  )
}

export function CooldownPeriod({ issues, week }: Props) {
  console.debug('rendering CooldownPeriod')

  const { config, profiles } = useRemoteData()

  const filteredIssues = issues.filter(cooldownPeriodFilter)

  const header = (
    <>
      Cooldown Period
      {week ? (
        <span style={{ fontWeight: 'normal' }}>
          {' '}
          &mdash; week {week}/{config.cooldown_duration_weeks}
        </span>
      ) : null}
    </>
  )

  return (
    <>
      <Callout intent="primary" icon="saved" header={header}>
        <p className={Classes.RUNNING_TEXT}>
          During the two week cooldown period, we fix bugs which were too
          complex to fix during regular bugfix Mondays, standalone issues
          prioritized by the product owner and issues the team believes are
          important.
        </p>
        <a
          href="https://app.gitbook.com/@exivity/s/docs/product/collaboration-guide/cycles#cooldown-period"
          target="_blank"
          rel="noreferrer"
        >
          Read more about the cooldown period on the internal docs
        </a>
      </Callout>
      <CardContainer>
        {filteredIssues.length === 0 ? (
          <p>No issues to work on.</p>
        ) : (
          filteredIssues.map((issue) => (
            <IssueCard key={issue.key} issue={issue} profiles={profiles} />
          ))
        )}
      </CardContainer>
    </>
  )
}
