import { Classes } from '@blueprintjs/core'
import { Issue, IssueStatus } from '@exivity/dashboard-common'
import React from 'react'
import { useRemoteData } from '../hooks'
import { Callout } from './Callout'
import { CardContainer } from './Card'
import { IssueCard } from './IssueCard'

type Props = {
  issues: Issue[]
}

function highPriorityFilter(issue: Issue) {
  return (
    // HighPriorityBugs
    ((['todo', 'started'] as IssueStatus[]).includes(issue.status) &&
      issue.type === 'bug' &&
      issue.priority === 'high') ||
    // BlockingNextRelease
    ((['todo', 'started'] as IssueStatus[]).includes(issue.status) &&
      issue.blockingRelease)
  )
}

export function HighPriority({ issues }: Props) {
  console.debug('rendering HighPriorityBugs')

  const { profiles } = useRemoteData()

  const filteredIssues = issues.filter(highPriorityFilter)

  return filteredIssues.length === 0 ? null : (
    <>
      <Callout intent="danger" icon="error" header="High Priority">
        <p className={Classes.RUNNING_TEXT}>
          <strong>High priority bugs</strong> should be fixed as soon as
          possible, meaning other ongoing work should be paused. We aim to fix
          critical bugs and release a fix within 24 hours of the bug being
          reported.
        </p>
        <p>
          <a
            href="https://app.gitbook.com/@exivity/s/docs/product/collaboration-guide/issues/bugs#high-priority"
            target="_blank"
            rel="noreferrer"
          >
            Read more about high priority bugs on the internal docs
          </a>
        </p>
        <p className={Classes.RUNNING_TEXT}>
          Issues marked as <strong>blocking next release</strong> should be
          prioritised over other issues in order to keep the develop branches
          releasable. We aim to fix blocking issues within a week.
        </p>
      </Callout>
      <CardContainer>
        {filteredIssues.length === 0 ? (
          <p>No issues to work on.</p>
        ) : (
          filteredIssues.map((issue) => (
            <IssueCard key={issue.key} issue={issue} profiles={profiles} />
          ))
        )}
      </CardContainer>
    </>
  )
}
