import { Classes } from '@blueprintjs/core'
import {
  getShowPreference,
  Issue,
  Project,
  ProjectStatus,
} from '@exivity/dashboard-common'
import React from 'react'
import { useRemoteData } from '../hooks'
import { Callout } from './Callout'
import { IssueCard } from './IssueCard'
import { ProjectContainer } from './ProjectContainer'
import { Switch } from './Switch'

type Props = {
  filteredIssues: Issue[]
  allIssues?: Issue[]
  projects: Project[]
  showEmptyProjects?: boolean
  week?: number
}

type GroupedIssues = {
  project: Project
  issues: Issue[]
}

function filterCycleProjects(project: Project) {
  return (['up-next', 'started'] as ProjectStatus[]).includes(project.status)
}

function filterIssuesByProject(project: Project) {
  return function (issue: Issue) {
    return issue.project === project.key
  }
}

function groupedIssuesSorter(a: GroupedIssues, b: GroupedIssues) {
  if (a.issues.length > 0 && b.issues.length > 0) {
    if (a.project.name < b.project.name) {
      return -1
    }
    if (a.project.name > b.project.name) {
      return 1
    }
    return 0
  }
  return b.issues.length - a.issues.length
}

export function ProjectCycle({
  filteredIssues,
  allIssues,
  projects,
  showEmptyProjects,
  week,
}: Props) {
  console.debug('rendering ProjectCycle')

  const { config, userProfile, setPreference, profiles } = useRemoteData()

  projects = projects.filter(filterCycleProjects)

  const issuesByProject = Object.values(projects)
    .map((project) => {
      const projectFilter = filterIssuesByProject(project)
      return {
        project,
        total: allIssues && allIssues.filter(projectFilter).length,
        issues: filteredIssues.filter(projectFilter),
      }
    })
    .sort(groupedIssuesSorter)
    .filter((groupedIssue) => {
      if (groupedIssue.issues.length === 0 && !showEmptyProjects) {
        return false
      }

      return true
    })

  const header = (
    <>
      Project Cycle
      {week ? (
        <span style={{ fontWeight: 'normal' }}>
          {' '}
          &mdash; week {week}/{config.project_cycle_duration_weeks}
        </span>
      ) : null}
    </>
  )

  return (
    <>
      <Callout intent="success" icon="heart" header={header}>
        <p className={Classes.RUNNING_TEXT}>
          Projects are groups of related cross-component issues. The goal of
          each cycle is to finish all projects in six weeks.
        </p>
        <a
          href="https://app.gitbook.com/@exivity/s/docs/product/collaboration-guide/cycles#projects"
          target="_blank"
          rel="noreferrer"
        >
          Read more about project cycles on the internal docs
        </a>
      </Callout>
      {issuesByProject.length === 0 ? (
        <div style={{ marginBottom: '1rem', display: 'grid' }}>
          <p>No projects to work on.</p>
          {getShowPreference(userProfile) === 'only_assigned' && (
            <p>
              Show unassigned issues and start working on a new issue:{' '}
              <Switch
                label="Unassigned"
                checked={false}
                onChange={(event: any) => {
                  setPreference('show', 'assigned_unassigned')
                }}
              />
            </p>
          )}
        </div>
      ) : (
        issuesByProject.map((item) => (
          <ProjectContainer
            key={item.project.key}
            project={item.project}
            tag={
              typeof item.total !== 'undefined'
                ? `Showing ${item.issues.length} / ${item.total}`
                : undefined
            }
          >
            {item.issues.length === 0 ? (
              <p>No issues to work on.</p>
            ) : (
              item.issues.map((issue) => (
                <IssueCard key={issue.key} issue={issue} profiles={profiles} />
              ))
            )}
          </ProjectContainer>
        ))
      )}
    </>
  )
}
