import {
  getComponentsPreference,
  getOnlyRecentPreference,
  Issue,
  PR,
  Project,
} from '@exivity/dashboard-common'
import { isAfter, sub } from 'date-fns'
import { debounce } from 'debounce'
import Fuse from 'fuse.js'
import { createContext, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { useRemoteData } from '../hooks'

type Search = {
  term: string
  setTerm: (term: string) => void
  searching: boolean
  results: Fuse.FuseResult<Issue | Project | PR>[]
}

type Props = {
  children: React.ReactNode
}

const initialState: Search = {
  term: '',
  setTerm: () => {},
  searching: false,
  results: [],
}

export const ONLY_RECENT_RANGE = {
  months: 3,
}

export const SearchContext = createContext(initialState)

export function SearchProvider({ children }: Props) {
  const [term, setTerm] = useState('')
  const [searching, setSearching] = useState(false)
  const [results, setResults] = useState<
    Fuse.FuseResult<Issue | Project | PR>[]
  >([])
  const [fuse, setFuse] = useState<Fuse<Issue | Project | PR>>()
  const { userProfile, allIssues, allProjects, allPrs } = useRemoteData()

  const components = useMemo(() => getComponentsPreference(userProfile), [
    userProfile,
  ])
  const onlyRecent = useMemo(() => getOnlyRecentPreference(userProfile), [
    userProfile,
  ])

  useEffect(() => {
    let documents

    if (components.length > 0) {
      documents = [
        ...[...allIssues, ...allProjects].filter((issueOrProject) => {
          return components.some((component) =>
            issueOrProject.components.includes(component)
          )
        }),
        ...allPrs.filter(
          (pr) => pr.component && components.includes(pr.component)
        ),
      ]
    } else {
      documents = [...allIssues, ...allProjects, ...allPrs]
    }

    if (onlyRecent) {
      const threshold = sub(new Date(), ONLY_RECENT_RANGE)
      documents = documents.filter((item) =>
        isAfter(new Date(item.updated), threshold)
      )
    }

    console.log(`creating new index from ${documents.length} documents...`)

    const fuse = new Fuse(documents, {
      // Whether the score should be included in the result set. A score of 0indicates a perfect match, while a score of 1 indicates a complete mismatch.
      includeScore: true,

      // Only the matches whose length exceeds this value will be returned. (For instance, if you want to ignore single character matches in the result, set it to 2).
      minMatchCharLength: 2,

      // When true, the matching function will continue to the end of a search pattern even if a perfect match has already been located in the string.
      findAllMatches: true,

      // List of keys that will be searched. This supports nested paths, weighted search, searching in arrays of strings and objects.
      keys: [
        {
          name: 'key', // issue, project, pr
          weight: 3,
        },
        {
          name: 'name', // issue, project
          weight: 2,
        },
        {
          name: 'title', // pr
          weight: 2,
        },
        {
          name: 'description', // issue, project, pr
          weight: 1,
        },
        {
          name: 'components', // issue, project
          weight: 0.4,
        },
        {
          name: 'component', // pr
          weight: 0.4,
        },
      ],

      // At what point does the match algorithm give up. A threshold of 0.0 requires a perfect match (of both letters and location), a threshold of 1.0 would match anything.
      threshold: 0.6,

      // When true, search will ignore location and distance, so it won't matter where in the string the pattern appears.
      ignoreLocation: true,

      // When true, it enables the use of unix-like search commands. See example: https://fusejs.io/examples.html#extended-search
      useExtendedSearch: true,
    })

    setFuse(fuse)

    // Each time onlyRecent or components update, we need to trigger a new search
    if (term.length >= 2) {
      console.debug('updating search results')
      setSearching(true)
      setTimeout(() => {
        const results = fuse.search(term)
        console.debug('got ' + results.length + ' results')
        // See https://stackoverflow.com/a/48610973
        ReactDOM.unstable_batchedUpdates(() => {
          setSearching(false)
          setResults(results)
        })
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIssues, allProjects, allPrs, components, onlyRecent])

  const searchDebounced = useMemo(() => {
    return debounce((term: string) => {
      if (fuse && term.length >= 2) {
        // See https://stackoverflow.com/a/48610973
        ReactDOM.unstable_batchedUpdates(() => {
          console.debug(
            // @ts-ignore
            'searching in ' + fuse.getIndex().size() + ' documents...'
          )
          console.time('search')

          const results = fuse.search(term)

          console.debug('got ' + results.length + ' results')
          console.timeEnd('search')

          setSearching(false)
          setResults(results)
        })
      }
    }, 500)
  }, [fuse])

  const value = {
    term,
    setTerm: (term: string) => {
      setTerm(term)
      setSearching(true)
      searchDebounced(term)
    },
    searching,
    results,
  }

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}
