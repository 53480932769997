import { Button, Classes, Icon, Tag } from '@blueprintjs/core'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import {
  Build,
  getFilterBuildsPreference,
  RepoBuild,
} from '@exivity/dashboard-common'
import { formatDistanceToNow } from 'date-fns'
import React from 'react'
import styled from 'styled-components'
import { useFunctionResult, useRemoteData } from '../hooks'
import { Callout } from './Callout'
import { Switch } from './Switch'
import { Table } from './Table'

const repoComponentMap: {
  [key: string]: string
} = {
  db: 'databases',
  docs: 'documentation',
  internal: 'documentation',
}

const Toolbar = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 1rem;
  }
`

const CommitLink = styled.a`
  display: block;
  text-align: center;
`

const Checks = styled.ul`
  list-style: none;
  padding: 1rem;
  margin: 0;

  li {
    margin: 0.2rem 0;
  }
`

const StatusTag = styled(Tag).attrs(() => ({
  large: true,
}))`
  width: 100px;
  text-align: center;
`

function buildSorter(a: RepoBuild, b: RepoBuild) {
  if (a.repoName < b.repoName) {
    return -1
  }
  if (a.repoName > b.repoName) {
    return 1
  }
  return 0
}

function makeBuildFilter(components: string[]) {
  return function (build: RepoBuild) {
    if (components.length === 0) {
      return true
    }

    const repo = repoComponentMap[build.repoName]
      ? repoComponentMap[build.repoName]
      : build.repoName

    return components
      .map((component) => component.toLowerCase())
      .includes(repo.toLowerCase())
  }
}

function Branch({ build }: { build: Build }) {
  return (
    <>
      <td>
        <Commit build={build} />
      </td>
      <td>
        <Status build={build} />
      </td>
    </>
  )
}

function Commit({ build }: { build: Build }) {
  return (
    <Tooltip2
      targetTagName="div"
      placement="right"
      content={
        <span>
          {formatDistanceToNow(new Date(build.date), { addSuffix: true })}{' '}
          &mdash; <strong>{build.author}</strong>
          <br />
          {build.message}
        </span>
      }
    >
      <CommitLink href={build.url} target="_blank" rel="noreferrer">
        <code>{build.sha}</code>
      </CommitLink>
    </Tooltip2>
  )
}

function Status({ build }: { build: Build }) {
  return (
    <Popover2
      targetTagName="div"
      interactionKind="hover"
      placement="right"
      content={
        build.checks.length > 0 ? (
          <Checks>
            {build.checks.map((check) => {
              return (
                <li>
                  <Icon
                    icon={
                      check.status === 'ERROR' || check.status === 'FAILURE'
                        ? 'cross'
                        : check.status === 'SUCCESS'
                        ? 'tick'
                        : 'dot'
                    }
                    intent={
                      check.status === 'ERROR' || check.status === 'FAILURE'
                        ? 'danger'
                        : check.status === 'SUCCESS'
                        ? 'success'
                        : 'warning'
                    }
                  />{' '}
                  <a href={check.url} target="_blank" rel="noreferrer">
                    {check.name}
                  </a>
                </li>
              )
            })}
          </Checks>
        ) : undefined
      }
    >
      <StatusTag
        minimal={build.checks.length === 0}
        intent={
          build.status === 'ERROR' || build.status === 'FAILURE'
            ? 'danger'
            : build.status === 'SUCCESS'
            ? 'success'
            : build.status === 'PENDING' && build.checks.length > 0
            ? 'warning'
            : 'none'
        }
      >
        {build.checks.length === 0 ? (
          <span className={Classes.TEXT_MUTED}>N/A</span>
        ) : (
          build.status
        )}
      </StatusTag>
    </Popover2>
  )
}

function NoCommit() {
  return (
    <td colSpan={2}>
      <div className={Classes.TEXT_MUTED} style={{ textAlign: 'center' }}>
        No commits found
      </div>
    </td>
  )
}

export function Builds() {
  console.debug('rendering Builds')

  const builds = useFunctionResult('builds')
  const { userProfile, setPreference } = useRemoteData()

  const filter = getFilterBuildsPreference(userProfile)

  return (
    <>
      <Callout
        icon="build"
        intent="primary"
        header="Builds"
        collapseButtonText="Options"
      >
        <p className={Classes.RUNNING_TEXT}>
          Realtime statuses on main branches for our repositories.
        </p>
        <Toolbar>
          <Button icon="refresh" onClick={() => builds && builds.refresh()}>
            Refresh
          </Button>
          <Switch
            label="Filter repositories"
            checked={filter}
            onChange={(event: any) => {
              setPreference('filterBuilds', event.target.checked)
            }}
          />
        </Toolbar>
      </Callout>
      <Table>
        <thead>
          <tr>
            <th>Repository</th>
            <th colSpan={2}>Release branch</th>
            <th colSpan={2}>Development branch</th>
          </tr>
          <tr>
            <th></th>
            <th>Latest commit</th>
            <th>Build status</th>
            <th>Latest commit</th>
            <th>Build status</th>
          </tr>
        </thead>
        <tbody>
          {builds
            ?.filter(
              makeBuildFilter(
                filter ? userProfile.preferences?.components || [] : []
              )
            )
            .sort(buildSorter)
            .map((repoBuild) => (
              <tr key={repoBuild.repoName}>
                <td>
                  <a
                    href={`https://github.com/exivity/${repoBuild.repoName}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>{repoBuild.repoName}</strong>
                  </a>
                </td>
                {repoBuild.branches.master ? (
                  <Branch build={repoBuild.branches.master} />
                ) : repoBuild.branches.main ? (
                  <Branch build={repoBuild.branches.main} />
                ) : (
                  <NoCommit />
                )}
                {repoBuild.branches.develop ? (
                  <Branch build={repoBuild.branches.develop} />
                ) : (
                  <NoCommit />
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  )
}
