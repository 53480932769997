import { mount, redirect, route } from 'navi'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Router, View } from 'react-navi'
import { HelmetProvider } from 'react-navi-helmet-async'
import {
  Builds,
  Done,
  ErrorBoundary,
  Header,
  Layout,
  Loading,
  Main,
  Navigation,
  Planning,
  Release,
  Roadmap,
  Search,
  SearchResults,
  Today,
  Toolbar,
} from './components'
import {
  AuthProvider,
  FirebaseProvider,
  RemoteDataProvider,
  SearchProvider,
  TodayProvider,
} from './context'
import './index.scss'
import reportWebVitals from './reportWebVitals'

const routes = mount({
  '/': redirect('/today'),
  '/today': route({
    title: 'Today',
    view: <Today />,
  }),
  '/planning': route({
    title: 'Planning',
    view: <Planning />,
  }),
  '/done': route({
    title: 'Done',
    view: <Done />,
  }),
  '/roadmap': route({
    title: 'Roadmap',
    view: <Roadmap />,
  }),
  '/search': route({
    title: 'Search',
    view: <SearchResults />,
  }),
  '/builds': route({
    title: 'Builds',
    view: <Builds />,
  }),
  '/release': route({
    title: 'Release',
    view: <Release />,
  }),
})

ReactDOM.render(
  <HelmetProvider>
    <Router routes={routes}>
      <Layout>
        <ErrorBoundary>
          <FirebaseProvider>
            <AuthProvider>
              <TodayProvider>
                <RemoteDataProvider>
                  <SearchProvider>
                    <Header>
                      <Navigation />
                      <Search />
                      <Toolbar />
                    </Header>
                    <Main>
                      <Suspense fallback={<Loading />}>
                        <View />
                      </Suspense>
                    </Main>
                  </SearchProvider>
                </RemoteDataProvider>
              </TodayProvider>
            </AuthProvider>
          </FirebaseProvider>
        </ErrorBoundary>
      </Layout>
    </Router>
  </HelmetProvider>,
  document.getElementById('react-root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
