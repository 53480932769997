import { Tag } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import {
  capitalize,
  Issue,
  IssueStatus,
  IssueType,
  Profile,
} from '@exivity/dashboard-common'
import React from 'react'
import nl2br from 'react-nl2br'
import styled from 'styled-components'
import { getProfile } from '../api'
import { Avatar } from './Avatar'
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardIdentifier,
  CardOverflowBody,
  CardTitle,
  CardUpdated,
} from './Card'

type IssueCardProps = {
  dot?: boolean
  issue: Issue
  profiles: Record<string, Profile>
}

type IssueTypeProps = {
  type: IssueType
}

type IssueStatusProps = {
  status: IssueStatus
  blocked: boolean
}

export const IssueTypeTag = styled(Tag).attrs<IssueTypeProps>(({ type }) => ({
  intent:
    type === 'bug'
      ? 'danger'
      : type === 'feature'
      ? 'success'
      : type === 'spike'
      ? 'warning'
      : 'primary',
  large: true,
  round: true,
  minimal: true,
  icon:
    type === 'bug'
      ? 'virus'
      : type === 'feature'
      ? 'heart'
      : type === 'chore'
      ? 'tick'
      : type === 'spike'
      ? 'help'
      : undefined,
  children: null,
}))<IssueTypeProps>`
  text-transform: uppercase;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`

export const IssueStatusTag = styled(Tag).attrs<IssueStatusProps>(
  ({ status, blocked }) => ({
    intent: blocked
      ? undefined
      : status === 'backlog'
      ? null
      : status === 'todo'
      ? 'primary'
      : status === 'started'
      ? 'success'
      : status === 'done'
      ? 'success'
      : status === 'released'
      ? 'success'
      : status === 'cancelled'
      ? 'danger'
      : undefined,
    minimal:
      status === 'backlog' ||
      status === 'cancelled' ||
      status === 'released' ||
      status === 'done',
    large: true,
    round: true,
    children: blocked ? 'Blocked' : status,
  })
)<IssueStatusProps>`
  text-transform: uppercase;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
`

export function IssueCard({ dot = false, issue, profiles }: IssueCardProps) {
  console.debug('rendering issue ' + issue.key)

  const assignee = getProfile(profiles, issue.assignee)

  return (
    <Card
      type={dot ? 'issue' : undefined}
      href={`https://exivity.atlassian.net/browse/${issue.key}`}
      intent={
        issue.priority === 'high'
          ? 'danger'
          : issue.blockingRelease
          ? 'warning'
          : undefined
      }
    >
      <CardHeader>
        <Tooltip2 content={capitalize(issue.type)} placement="top">
          <IssueTypeTag type={issue.type} />
        </Tooltip2>
        <IssueStatusTag blocked={issue.blocked} status={issue.status} />
      </CardHeader>
      <CardOverflowBody>
        <CardTitle>{issue.name}</CardTitle>
        <CardDescription>{nl2br(issue.description)}</CardDescription>
      </CardOverflowBody>
      <CardFooter>
        {assignee ? (
          <Tooltip2
            content={assignee.displayName || assignee.email || 'Unknown'}
            placement="top"
          >
            <Avatar profile={assignee} small />
          </Tooltip2>
        ) : null}
        <CardIdentifier>{issue.key}</CardIdentifier>
        {issue.components.map((component) => (
          <CardIdentifier key={component} minimal>
            {component}
          </CardIdentifier>
        ))}
        {issue.priority === 'high' ? (
          <CardIdentifier intent="danger">High priority</CardIdentifier>
        ) : null}
        {issue.blockingRelease ? (
          <CardIdentifier intent="warning">
            Blocking next release
          </CardIdentifier>
        ) : null}
        <CardUpdated updated={issue.updated} />
      </CardFooter>
    </Card>
  )
}
