import { Classes } from '@blueprintjs/core'
import { getShowPreference, PR } from '@exivity/dashboard-common'
import React from 'react'
import { useRemoteData } from '../hooks'
import { Callout } from './Callout'
import { CardContainer } from './Card'
import { PrCard } from './PrCard'

type Props = {
  prs: PR[]
}

export function PRs({ prs }: Props) {
  console.debug('rendering PRs')

  const { userProfile, profiles } = useRemoteData()
  const show = getShowPreference(userProfile)

  return (
    <>
      <Callout id="pr" intent="primary" icon="git-pull" header="Pull requests">
        <p className={Classes.RUNNING_TEXT}>
          Please prioritize pull request reviews, it helps others move forward
          and speeds up development.
        </p>
        <a
          href="https://app.gitbook.com/@exivity/s/docs/product/collaboration-guide/pull-requests"
          target="_blank"
          rel="noreferrer"
        >
          Read more about pull requests on the internal docs
        </a>
      </Callout>
      <CardContainer>
        {show === 'include_complete' ? (
          <p>Pull requests not shown in 'Everything' mode.</p>
        ) : prs.length === 0 ? (
          <p>No pull requests which need attention.</p>
        ) : (
          prs.map((pr) => <PrCard key={pr.key} pr={pr} profiles={profiles} />)
        )}
      </CardContainer>
    </>
  )
}
