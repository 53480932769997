import { IconName, Intent, Tag } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import { PR, Profile } from '@exivity/dashboard-common'
import React from 'react'
import nl2br from 'react-nl2br'
import styled from 'styled-components'
import { getProfile } from '../api'
import { Avatar } from './Avatar'
import {
  Card,
  CardAvatars,
  CardDescription,
  CardFooter,
  CardHeader,
  CardIdentifier,
  CardOverflowBody,
  CardTitle,
  CardUpdated,
} from './Card'
import { Tooltip } from './Tooltip'

type PrCardProps = {
  dot?: boolean
  pr: PR
  profiles: Record<string, Profile>
}

type PrStatusProps = {
  statusData: ReturnType<typeof statusData>
}

function prettify(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).replace('_', ' ')
}

function statusData(pr: PR) {
  const status = {
    label: prettify(pr.status),
    tooltip: `PR status: ${prettify(pr.status)}`,
    intent: (pr.draft
      ? 'primary'
      : pr.status === 'changes_requested'
      ? 'danger'
      : pr.status === 'review_required'
      ? 'warning'
      : pr.status === 'approved'
      ? 'success'
      : pr.status === 'closed'
      ? 'danger'
      : pr.status === 'merged'
      ? 'success'
      : undefined) as Intent,
    icon: (pr.draft
      ? 'build'
      : pr.status === 'changes_requested'
      ? 'changes'
      : pr.status === 'review_required'
      ? 'eye-open'
      : pr.status === 'approved'
      ? 'tick'
      : pr.status === 'closed'
      ? 'cross'
      : pr.status === 'merged'
      ? 'git-merge'
      : undefined) as IconName,
  }

  if (pr.draft) {
    status.label = 'Draft'
    status.tooltip = 'Mark as ready for review once work is completed'
  } else if (pr.status === 'approved') {
    status.label = 'Please merge'
  } else if (pr.status === 'review_required' && pr.reviewers.length > 0) {
    status.label = 'Please review'
  } else if (pr.status === 'review_required' && pr.reviewers.length === 0) {
    status.label = 'Please add reviewers'
  } else if (pr.status === 'changes_requested') {
    status.label = 'Please address review'
  }

  return status
}

export const PrStatusTag = styled(Tooltip).attrs<PrStatusProps>(
  ({ statusData }) => ({
    content: statusData.tooltip,
    placement: 'top',
    children: (
      <Tag
        intent={statusData.intent}
        large
        round
        minimal
        icon={statusData.icon}
      >
        {statusData.label}
      </Tag>
    ),
  })
)<PrStatusProps>`
  text-transform: uppercase;
`

export function PrCard({ dot = false, pr, profiles }: PrCardProps) {
  console.debug('rendering pr ' + pr.key)

  const assignee = getProfile(profiles, pr.assignee)
  const reviewers = pr.reviewers.map(
    (reviewer) => getProfile(profiles, reviewer)!
  )

  return (
    <Card
      type={dot ? 'pr' : undefined}
      href={`https://github.com/${pr.repo}/pull/${pr.number}`}
    >
      <CardHeader>
        <PrStatusTag statusData={statusData(pr)} />
      </CardHeader>
      <CardOverflowBody>
        <CardTitle>{pr.title}</CardTitle>
        <CardDescription>{nl2br(pr.description)}</CardDescription>
      </CardOverflowBody>
      <CardFooter>
        <CardAvatars>
          {assignee &&
          (pr.status !== 'review_required' || reviewers.length === 0) ? (
            <Tooltip2
              content={`Assigned to ${
                assignee.displayName || assignee.email || 'Unknown'
              }`}
              placement="top"
            >
              <Avatar profile={assignee} small />
            </Tooltip2>
          ) : null}
          {reviewers.map((reviewer, index) => (
            <Tooltip2
              key={reviewer.displayName || reviewer.email || `unknown:${index}`}
              content={`Pending review from ${
                reviewer.displayName || reviewer.email || 'Unknown'
              }`}
              placement="top"
            >
              <Avatar profile={reviewer} small />
            </Tooltip2>
          ))}
        </CardAvatars>
        <CardIdentifier>#{pr.number}</CardIdentifier>
        <CardIdentifier minimal>{pr.component || pr.repo}</CardIdentifier>
        <CardUpdated updated={pr.updated} />
      </CardFooter>
    </Card>
  )
}
