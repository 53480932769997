import { Classes } from '@blueprintjs/core'
import { Project } from '@exivity/dashboard-common'
import React from 'react'
import styled from 'styled-components'
import { useDarkMode } from '../hooks'
import { CardContainer } from './Card'
import { ProjectCard } from './ProjectCard'

type Props = {
  projects: Project[]
  title: string
}

const StyledRoadmapContainer = styled.div`
  margin-bottom: 2rem;
`

const StyledTitle = styled.h2.attrs(() => ({
  className: Classes.HEADING,
}))<{ darkMode: boolean }>`
  position: sticky;
  top: calc(30px + 2rem);
  z-index: 1;

  padding-bottom: 0.5rem;
  background-color: ${({ darkMode }) => (darkMode ? '#24282f' : '#f8f8f8')};
`

export function RoadmapContainer({ projects, title }: Props) {
  const darkMode = useDarkMode()

  return (
    <StyledRoadmapContainer>
      <StyledTitle darkMode={darkMode.value}>{title}</StyledTitle>
      <CardContainer>
        {projects.map((project) => (
          <ProjectCard project={project} />
        ))}
      </CardContainer>
    </StyledRoadmapContainer>
  )
}
