import { Placement, Tooltip2 } from '@blueprintjs/popover2'
import gravatar from 'gravatar'
import styled, { css } from 'styled-components'

type Props = {
  profile: {
    email: string | null
    displayName?: string | null
    photoURL?: string | null
  }
  tooltip?: Placement
  small?: boolean
  noMargin?: boolean
}

const DEFAULT_SRC =
  'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortWaved&accessoriesType=Prescription02&hairColor=Red&facialHairType=Blank&clotheType=Hoodie&clotheColor=Gray02&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light'

export const Avatar = styled.div.attrs<Props>(({ profile, tooltip }) => {
  const inner = (
    <div>
      <img
        alt={profile.displayName || ''}
        src={
          profile.photoURL ||
          (profile.email
            ? gravatar.url(profile.email, { d: 'robohash' })
            : DEFAULT_SRC)
        }
      />
    </div>
  )

  return {
    children: tooltip ? (
      <Tooltip2
        content={profile.displayName || profile.email || 'Unknown'}
        placement={tooltip}
      >
        {inner}
      </Tooltip2>
    ) : (
      inner
    ),
  }
})<Props>`
  width: ${({ small }) => (small ? '20px' : '30px')};
  height: ${({ small }) => (small ? '20px' : '30px')};

  ${({ noMargin, small }) =>
    noMargin
      ? ''
      : css`
          margin-right: ${small ? '0.5rem' : '1rem'};
        `}

  div {
    overflow: hidden;
    border-radius: 50%;
  }

  img {
    display: block;
    width: ${({ small }) => (small ? '20px' : '30px')};
    height: ${({ small }) => (small ? '20px' : '30px')};
  }
`
