import type firebase from 'firebase/app'
import { useEffect, useMemo, useState } from 'react'
import { useApp } from './useApp'

export type Collection =
  | 'songs'
  | 'profiles'
  | 'issues'
  | 'config'
  | 'projects'
  | 'prs'

export function useFirestore() {
  const app = useApp()
  const firestore = useMemo(() => {
    return app.firestore()
  }, [app])

  return firestore
}

export function useCollectionRef(collection: Collection) {
  const firestore = useFirestore()

  return useMemo(() => firestore.collection(collection), [
    collection,
    firestore,
  ])
}

export function useDocRef(collection: Collection, id: string) {
  const firestore = useFirestore()

  return useMemo(() => firestore.collection(collection).doc(id), [
    collection,
    firestore,
    id,
  ])
}

export function useSnapshot<T extends firebase.firestore.DocumentData>(
  collection: Collection
) {
  const [snapshot, setSnapshot] = useState<
    firebase.firestore.QuerySnapshot<T>
  >()
  const collectionRef = useCollectionRef(collection)

  useEffect(() => {
    console.debug('registering snapshot listener for ' + collection)

    const unsubscribe = collectionRef.onSnapshot(function (querySnapshot) {
      console.debug('received new snapshot for ' + collection)

      setSnapshot(querySnapshot as firebase.firestore.QuerySnapshot<T>)
    })

    return () => {
      console.debug('unregistering snapshot listener for ' + collection)

      unsubscribe()
    }
  }, [collection, collectionRef])

  return snapshot
}

export function useCollectionMap<T extends firebase.firestore.DocumentData>(
  collection: Collection
) {
  const snapshot = useSnapshot(collection)

  if (snapshot) {
    const data: Record<string, T> = {}

    snapshot.forEach(function (doc) {
      data[doc.id] = doc.data() as T
    })

    return data
  }
}

export function useCollectionSet<T extends firebase.firestore.DocumentData>(
  collection: Collection
) {
  const snapshot = useSnapshot(collection)

  if (snapshot) {
    const data: T[] = []

    snapshot.forEach(function (doc) {
      data.push(doc.data() as T)
    })

    return data
  }
}

export function useDoc<T extends firebase.firestore.DocumentData>(
  collection: Collection,
  id: string
) {
  const [data, setData] = useState<T>()
  const docRef = useDocRef(collection, id)

  useEffect(() => {
    console.debug('registering snapshot listener for ' + collection + ':' + id)

    const unsubscribe = docRef.onSnapshot(function (doc) {
      console.debug('received new snapshot for ' + collection + ':' + id)

      setData(doc.data() as T)
    })

    return () => {
      console.debug(
        'unregistering snapshot listener for ' + collection + ':' + id
      )

      unsubscribe()
    }
  }, [collection, docRef, id])

  return data
}

export function useDocField<T>(
  collection: Collection,
  id: string,
  field: string
) {
  const doc = useDoc(collection, id)

  if (doc) {
    return doc[field] as T
  }
}
