import { Project } from '@exivity/dashboard-common'
import React from 'react'
import {
  Card,
  CardDescription,
  CardFooter,
  CardIdentifier,
  CardOverflowBody,
  CardTitle,
  CardUpdated,
} from './Card'
import { ProjectSpecButton } from './ProjectSpecButton'

type Props = {
  dot?: boolean
  project: Project
}

export function ProjectCard({ dot = false, project }: Props) {
  return (
    <Card
      type={dot ? 'project' : undefined}
      href={`https://exivity.atlassian.net/browse/${project.key}`}
    >
      <CardOverflowBody>
        <CardTitle>
          <span>{project.name}</span>
          {project.specURL && <ProjectSpecButton project={project} />}
        </CardTitle>
        <CardDescription>{project.description}</CardDescription>
      </CardOverflowBody>
      <CardFooter>
        <CardIdentifier>{project.key}</CardIdentifier>
        {project.components.map((component) => (
          <CardIdentifier key={component} minimal>
            {component}
          </CardIdentifier>
        ))}
        <CardUpdated updated={project.updated} />
      </CardFooter>
    </Card>
  )
}
